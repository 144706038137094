<template>
  <div
    class="auth-layout-wrap"
  >
    <div class="auth-content">
      <div class="card o-hidden custom-form">
        <div class="row">
          <div class="col-md-12">
            <div class="p-80">
              <div class="text-center">
                <h1 class="mb-3 text-30 font-weight-bold">Forgot Password</h1>
                <p class="text-15">Please enter your phone number here.</p>
              </div>
              <form action="" class="mt-30">
                <b-form-group label="Email Address" class="text-12">
                  <b-form-input
                    type="text"
                    v-model="email"
                    email
                    required
                    size="lg"
                  ></b-form-input>
                </b-form-group>
                <div class="d-flex justify-content-between">
                  <b-button class="btn btn-block mt-30" size="lg">
                    Cancel
                  </b-button>
                  <b-button variant="primary" class="btn btn-block mt-30 ml-30" size="lg" @click="verifyOtp">
                    Submit
                  </b-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Forgot Password"
  },
  data() {
    return {
    };
  },
  methods: {
    verifyOtp() {
      this.$router.push("/app/sessions/verify");
    }
  }
};
</script>

<style lang="scss">
.auth-layout-wrap:after {
    content: '';
    background-image: url("~@/assets/images/bg/startup-bg.jpeg");
    width: 100%;
    height: 100%;
    position:absolute;
    top:0;
    left:0;
    background-size: cover;
}
</style>